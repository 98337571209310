html, body, * {
    font-family: Roboto -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin: 0;
}

body {
    padding: 40px;
}

html, body, #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

#renderer-content {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 992px) {
    #renderer-content {
        flex-direction: column-reverse;
    }
}

#title {
    padding: 20px;
}

.drop-shadow {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}

#control-panel,
#control-panel-controls {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
}

#control-panel {
    min-width: 300px;
}

#control-panel-controls > * {
    margin-bottom: 16px;
}

#fractal-container {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    min-width: 200px;
    min-height: 200px;
}

@media only screen and (max-width: 992px) {
    #fractal-container {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: 992px) {
    #fractal-container {
        width: 100%;
    }
}

#controls-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 80px;
}

#drag-handle {
    position: absolute;
    margin-left: -15px;
    margin-top: 25px;
    z-index: 2;
    width: 30px;
    height: 30px;
    border: 0;
    text-align: center;
    cursor: grab;
    border-radius: 50%;
}

#slider-label {
    font-size: 11px;
    margin-top: 36px;
}

#undo-button {
    position: absolute;
    margin: 8px;
}

#external-links {
    padding: 20px;
}

#renderer-size-watcher {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
}
